import NotFound from './components/layout/NotFound';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { VesselsOverview } from './features/vesselsOverview';

export interface RouteDefinition {
  path: string;
  component: JSX.Element;
  title?: string;
  icon?: JSX.Element;
  showInSidebar?: boolean; // If this is true, title and icon should NOT be empty
}

export const routes: RouteDefinition[] = [
  {
    path: '/',
    component: <VesselsOverview />,
    title: 'Vessels Overview',
    icon: <DashboardIcon />,
    showInSidebar: true
  },
  {
    path: '*',
    component: <NotFound />
  }
].flat();
