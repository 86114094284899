import { User, UserManager } from 'oidc-client';
import { CENTRALIZED_VESSEL_ETA_API_ASSET_ID } from '../constants';

const settings = {
  authority: process.env.REACT_APP_STS_AUTHORITY,
  client_id: process.env.REACT_APP_STS_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_STS_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_STS_POST_LOGOUT_REDIRECT_URI,
  silent_redirect_uri: process.env.REACT_APP_STS_SILENT_REDIRECT_URI,
  scope: 'openid profile nxt:etazeeschepen:bff:write nxt:etazeeschepen:bff:read',
  response_type: 'code',
  automaticSilentRenew: true
};
const userManager = new UserManager(settings);

async function getAuthenticatedUserAsync(): Promise<User | null> {
  const user: User | null = await userManager.getUser();

  if (user && !user.expired) {
    return user;
  } else {
    return null;
  }
}

export function signinRedirect(): void {
  userManager.signinRedirect();
}

export function signinRedirectCallback(): void {
  userManager.signinRedirectCallback().then(() => {
    window.location.href = '/';
  });
}

export function signoutRedirect(): void {
  userManager.signoutRedirect();
}

export function signoutRedirectCallback(): void {
  userManager.signoutRedirectCallback().then(() => {
    window.location.href = '/';
  });
}

export function signinSilentCallback(): void {
  userManager.signinSilentCallback();
}

export async function getAccessTokenAsync(): Promise<string | undefined> {
  const user: User | null = await getAuthenticatedUserAsync();

  return user?.access_token;
}

export async function getNameAsync(): Promise<string | undefined> {
  const user: User | null = await getAuthenticatedUserAsync();

  return user?.profile.name;
}

export async function isAuthenticatedAsync(): Promise<boolean> {
  const user: User | null = await getAuthenticatedUserAsync();

  return !!user;
}

export async function isAuthorizedAsync(): Promise<boolean> {
  const user: User | null = await getAuthenticatedUserAsync();
  const subscriptionsFromClaim = user?.profile['nxt:entity:subscriptions'];

  if (!subscriptionsFromClaim) {
    return false;
  }

  return subscriptionsFromClaim.includes(CENTRALIZED_VESSEL_ETA_API_ASSET_ID);
}
