import { Snackbar, Alert } from '@mui/material';
import { useNotificationStore } from '../../stores/notifications';

export default function Notifications() {
  const { notifications, dismissNotification } = useNotificationStore();

  const onClose = (notificationId: string) => {
    dismissNotification(notificationId);
  };

  /*
  MUI only allows 1 snackbar at a time, because having multiple snackbars "is discouraged by the Material Design specification"
  If we must have multiple snackbars, there is an option: https://v4.mui.com/components/snackbars/#notistack
   */
  return (
    <div>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={true}
          autoHideDuration={6000}
          onClose={() => onClose(notification.id)}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => onClose(notification.id)}
            severity={notification.type}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </div>
  );
}
