import PagingParameters from '../../../types/pagingParameters';
import { useEffect, useState } from 'react';
import VesselInfo from '../types/VesselInfo';
import SearchVesselInfoParameters, {
  SearchVesselInfoOrderBy,
  SortOrder
} from '../types/searchVesselInfoParameters';
import { getVesselsInfo } from '../api/vesselEtaApiService';

const getInitialPaging = (): PagingParameters => {
  return {
    page: 1,
    pageSize: 25,
    totalElements: 0
  };
};

const getInitialFilters = () => ({
  terminalOperatorCode: null,
  terminalCode: null,
  vesselName: null,
  imo: null,
  etaFrom: null
});

const getInitialSearchParams = (): SearchVesselInfoParameters => {
  // split into 2 methods so we can reuse functions to reset search without resetting paging parameters
  return {
    ...getInitialPaging(),
    ...getInitialFilters(),
    orderBy: SearchVesselInfoOrderBy.eta,
    order: SortOrder.desc
  };
};

export function useVesselInfoSearch() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [vesselInfoList, setVesselInfoList] = useState<Array<VesselInfo>>(new Array<VesselInfo>());
  const [searchParams, setSearchParams] = useState<SearchVesselInfoParameters>(
    getInitialSearchParams()
  );
  const onSearch = () => setIsLoading(true);

  const clearSearch = () => {
    setSearchParams({
      ...searchParams,
      ...getInitialFilters()
    });
    onSearch();
  };

  const onChangePagingParameters = async (page: number, pageSize: number): Promise<void> => {
    setSearchParams({
      ...searchParams,
      page,
      pageSize
    });
    onSearch();
  };

  const onChangeSearchParam = (name: string, value: string) => {
    setSearchParams({
      ...searchParams,
      [name]: value === '' ? null : value
    });
  };

  const onReplaceSearchParam = (params: SearchVesselInfoParameters) => {
    setSearchParams(params);
    onSearch();
  };

  const onChangeDateSearchParam = (inputName: string, date: Date | null) => {
    setSearchParams({
      ...searchParams,
      [inputName]: date
    });
  };

  const onChangeOrdering = (orderBy: SearchVesselInfoOrderBy, order: SortOrder) => {
    setSearchParams({
      ...searchParams,
      orderBy: orderBy,
      order: order
    });
    onSearch();
  };

  useEffect(() => {
    const loadVesselInfo = async () => {
      try {
        const apiResponse = await getVesselsInfo(searchParams);
        setVesselInfoList(apiResponse.items);
        setSearchParams({
          ...searchParams,
          page: apiResponse.paging.page,
          pageSize: apiResponse.paging.pageSize,
          totalElements: apiResponse.paging.totalElements
        });
      } finally {
        setIsLoading(false);
      }
    };

    // Updating the paging params or clicking the Search Button set isLoading to true.
    if (isLoading) {
      loadVesselInfo();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    vesselInfoList,
    searchParams,
    isLoading,
    onSearch,
    onChangeSearchParam,
    onReplaceSearchParam,
    onChangeDateSearchParam,
    onChangePagingParameters,
    onChangeOrdering,
    clearSearch,
    getInitialFilters
  };
}
