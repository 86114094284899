import SearchVesselInfoParameters from '../types/searchVesselInfoParameters';
import DataInfoItemResponse from '../types/DataInfoItemResponse';
import GetVesselsInfoResult from '../types/GetVesselInfoResult';
import GetVesselsInfoResponse from '../types/GetVesselsInfoResponse';
import VesselInfo from '../types/VesselInfo';
import { mapGetVesselInfoResponse } from '../mappers/vesselInfoMapper';
import PagingQueryParameters from '../../../types/pagingQueryParameters';
import { axios } from '../../../lib/axios';
import { useNotificationStore } from '../../../stores/notifications';

export interface GetVesselsInfoParameters
  extends SearchVesselInfoParameters,
    PagingQueryParameters {}

export async function getVesselsInfo(
  parameters: GetVesselsInfoParameters
): Promise<GetVesselsInfoResult> {
  try {
    const response = await axios.get<GetVesselsInfoResponse>(`vessels-info`, {
      params: parameters
    });
    if (response.data?.items.length === 0) {
      useNotificationStore
        .getState()
        .addInfoNotification('No items found', 'No items were found for given search criteria.');
    }

    return mapGetVesselInfoResponse(response.data);
  } catch (err) {
    // error gets handled by axios response interceptor
    return {
      items: new Array<VesselInfo>(),
      paging: {
        pageSize: parameters.pageSize,
        page: parameters.page,
        totalElements: 0
      }
    };
  }
}

export async function getDataInfo(): Promise<Array<DataInfoItemResponse>> {
  try {
    const response = await axios.get<Array<DataInfoItemResponse>>(`data-info`);
    return response.data;
  } catch (err) {
    // error gets handled by axios response interceptor
    return [];
  }
}
