import React, { ErrorInfo } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Link, Typography } from '@mui/material';
import SinkingIconSvg from '../layout/sinking.svg';

const PREFIX = 'ErrorBoundary';

const classes = {
  icon: `${PREFIX}-icon`,
  bold: `${PREFIX}-bold`,
  gridContainer: `${PREFIX}-gridContainer`
};

const StyledGrid = styled(Grid)({
  [`& .${classes.icon}`]: {
    height: '100px'
  },
  [`& .${classes.bold}`]: {
    fontWeight: 600
  },
  [`&.${classes.gridContainer}`]: {
    alignItems: 'center',
    justifyContent: ' center',
    marginTop: 30
  }
});

interface IState {
  errorMessage: string;
}

const supportUrl = process.env.REACT_APP_POAOPENDATA_SUPPORT_URL || '';

class ErrorBoundary extends React.Component<any, IState> {
  state = {
    errorMessage: ''
  };

  static getDerivedStateFromError(error: Error) {
    return {
      errorMessage: error.toString()
    };
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, info: ErrorInfo) {
    // TODO [PLT-1138]: log error
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <StyledGrid container direction="column" className={classes.gridContainer}>
          <Grid item>
            <img src={SinkingIconSvg} alt="Sinking icon" className={classes.icon} />
          </Grid>

          <Grid container direction="column" className={classes.gridContainer}>
            <Typography className={classes.bold}>Oops. Something went wrong.</Typography>

            <Grid item>
              If this problem persist, please contact{' '}
              <Link id="CreateSupportTicketLink" href={supportUrl} underline="hover">
                NxtPort support
              </Link>
              .
            </Grid>
          </Grid>
        </StyledGrid>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
