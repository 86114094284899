import VesselsOverviewFilter from './VesselsOverviewFilter';
import VesselsOverviewList from './VesselsOverviewList';
import { Grid, Typography } from '@mui/material';
import DataSyncInfoList from './DataSyncInfoList';
import { useDataInfoList } from '../hooks/UseDataInfoList';
import { useVesselInfoSearch } from '../hooks/UseVesselInfoSearch';
import { useEffect } from 'react';
import {
  DISCLAIMER_TEXT,
  DISCLAIMER_TEXT_MPET,
  DISCLAIMER_TEXT_MPET_OPENING_TIME
} from '../../../constants';

export function VesselsOverview() {
  const dataInfoList = useDataInfoList();

  const {
    vesselInfoList,
    searchParams,
    isLoading,
    onSearch,
    onChangeSearchParam,
    onReplaceSearchParam,
    onChangeDateSearchParam,
    onChangePagingParameters,
    onChangeOrdering,
    getInitialFilters,
    clearSearch
  } = useVesselInfoSearch();

  useEffect(
    () => {
      onSearch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Grid id="TitleContainer" container justifyContent={'flex-end'}>
        <Grid item sx={{ marginRight: 'auto' }}>
          <Typography variant="h5">ETA Terminal</Typography>
        </Grid>
        <DataSyncInfoList dataInfoItems={dataInfoList} />
      </Grid>
      <VesselsOverviewFilter
        searchParams={searchParams}
        isLoading={isLoading}
        dataInfoItems={dataInfoList}
        onSearch={onSearch}
        clearSearch={clearSearch}
        onChangeSearchParam={onChangeSearchParam}
        onReplaceSearchParam={onReplaceSearchParam}
        getInitialFilters={getInitialFilters}
        onChangeDateSearchParam={onChangeDateSearchParam}
      />
      <VesselsOverviewList
        searchParams={searchParams}
        onChangePagingParameters={onChangePagingParameters}
        onChangeOrdering={onChangeOrdering}
        vesselInfoList={vesselInfoList}
      />
      {vesselInfoList.length > 0 && (
        <Grid
          id="DisclaimerContainer"
          container
          alignContent={'flex-start'}
          justifyContent={'center'}
          margin={'auto'}
          sx={{ width: 'calc(100% - 300px)' }}
        >
          <Grid item padding={'20px'}>
            <Typography
              variant="body2"
              fontSize={11}
              fontStyle="italic"
              fontWeight="bold"
              align="center"
              sx={{ marginBottom: '20px' }}
            >
              {DISCLAIMER_TEXT}
            </Typography>
            <Typography
              variant="body2"
              fontSize={11}
              fontStyle="italic"
              fontWeight="bold"
              align="center"
              sx={{ marginBottom: '20px' }}
            >
              {DISCLAIMER_TEXT_MPET}
            </Typography>
            <Typography
              variant="body2"
              fontSize={11}
              fontStyle="italic"
              fontWeight="bold"
              align="center"
            >
              {DISCLAIMER_TEXT_MPET_OPENING_TIME}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
