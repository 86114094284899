import { Box, LinearProgress } from '@mui/material';

interface Props {
  isLoading: boolean;
}

export default function LinearLoadingIndicator(props: Props) {
  return props.isLoading ? (
    <Box id="LoadingIndicator" sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  ) : (
    // 4px height to avoid jumping when LinearProgress appears
    <div id="LoadingIndicatorPlaceholder" style={{ height: '4px' }} />
  );
}
