import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { routes } from '../../routes';
import TopBar from './TopBar';
import { useContext } from 'react';
import AuthorizedContext from '../auth/AuthorizedContext';
import Forbidden from './Forbidden';
import { Route, Routes } from 'react-router-dom';
import ErrorBoundary from '../errorhandling/ErrorBoundary';

const PREFIX = 'Layout';

const classes = {
  root: `${PREFIX}-root`,
  topBar: `${PREFIX}-topBar`,
  content: `${PREFIX}-content`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex'
  },

  [`& .${classes.topBar}`]: theme.mixins.toolbar,

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

export default function Layout() {
  const isAuthorized: boolean = useContext(AuthorizedContext);

  const mainContent = (
    <Box>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Routes>
    </Box>
  );

  return (
    <Root className={classes.root}>
      <TopBar />

      <main className={classes.content}>
        <div className={classes.topBar} />
        <ErrorBoundary>
          {isAuthorized && mainContent}
          {!isAuthorized && <Forbidden />}
        </ErrorBoundary>
      </main>
    </Root>
  );
}
