import { nanoid } from 'nanoid';
import create from 'zustand';

export type Notification = {
  id: string;
  type: 'info' | 'warning' | 'success' | 'error';
  title: string;
  message?: string;
};

type NotificationsStore = {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  addErrorNotification: (title: string, message: string) => void;
  addInfoNotification: (title: string, message: string) => void;
  dismissNotification: (id: string) => void;
};

export const useNotificationStore = create<NotificationsStore>((set) => ({
  notifications: [],
  addNotification: (notification) =>
    set((state) => ({
      notifications: [
        ...state.notifications,
        {
          id: nanoid(),
          ...notification
        }
      ]
    })),
  addErrorNotification: (title: string, message: string) =>
    set((state) => ({
      notifications: [
        ...state.notifications,
        {
          id: nanoid(),
          type: 'error',
          title,
          message
        }
      ]
    })),
  addInfoNotification: (title: string, message: string) =>
    set((state) => ({
      notifications: [
        ...state.notifications,
        {
          id: nanoid(),
          type: 'info',
          title,
          message
        }
      ]
    })),
  dismissNotification: (id) =>
    set((state) => ({
      notifications: state.notifications.filter((notification) => notification.id !== id)
    }))
}));
