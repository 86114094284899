import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import POALogo from './portOfAntwerpLogo2.png';

const PREFIX = 'CompanyLogoLink';

const classes = {
  logoImg: `${PREFIX}-logoImg`
};

const StyledLink = styled(Link)(() => ({
  [`& .${classes.logoImg}`]: {
    height: '45px'
  }
}));

export default function CompanyLogoLink() {
  return (
    <StyledLink id="TopBarHomeLink" to="/">
      <img src={POALogo} alt="Company logo" className={classes.logoImg} />
    </StyledLink>
  );
}
