import { AppBar, Button, Menu, MenuItem, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import CompanyLogoLink from './CompanyLogoLink';
import { signoutRedirect } from '../../services/authService';
import useUserName from './UseUserName';
import useMenu from './UseMenu';

const PREFIX = 'TopBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  companyLogoLink: `${PREFIX}-companyLogoLink`
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1
  },

  [`& .${classes.companyLogoLink}`]: {
    flexGrow: 1
  }
}));

export default function TopBar() {
  const userName = useUserName();
  const { anchorEl, handleClick, handleClose } = useMenu();

  return (
    <StyledAppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <div className={classes.companyLogoLink}>
          <CompanyLogoLink />
        </div>
        <Button
          id="NxtportConsoleLinkButton"
          href={process.env.REACT_APP_NXTPORT_CONSOLE_URL}
          color="inherit"
        >
          NxtPort Console
        </Button>
        |
        <div>
          <Button
            id="UserNameButton"
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {userName ?? 'User'}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem id="LogoutButton" onClick={signoutRedirect}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </StyledAppBar>
  );
}
