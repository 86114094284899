import { Grid, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import format from 'date-fns/format';
import DataInfoItem from '../types/DataInfoItem';
import { DataSyncStatus } from '../types/DataSyncStatus';
import { Colours } from '../../../theme';

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(5)
}));

const StyledTypography = styled(Typography)(() => ({
  margin: '0px',
  marginTop: '0px',
  fontSize: '12px',
  color: 'gray'
}));

interface Props {
  dataInfoItems: Array<DataInfoItem>;
}

const dateFormat = 'yyyy-MM-dd hh-mm';

const formatSyncDateTime = (lastSuccessDateTime: Date | null): string => {
  try {
    return lastSuccessDateTime ? format(lastSuccessDateTime, dateFormat) : 'unknown';
  } catch (err: unknown) {
    console.error('lastSuccessDateTime could not be formatted.');
    return 'unknown';
  }
};

const getTooltipText = (dataInfoItem: DataInfoItem): string => {
  if (!dataInfoItem.lastSuccessDateTime) {
    return `Could not determine last data sync moment for ${dataInfoItem.terminalOperatorCode}.`;
  }

  return `Status of last data sync for ${dataInfoItem.terminalOperatorCode} is ${
    dataInfoItem.status
  }. The last successful sync was at ${formatSyncDateTime(dataInfoItem.lastSuccessDateTime)}.`;
};

export default function DataSyncInfoList(props: Props) {
  return (
    <>
      {props.dataInfoItems.map((dataInfoItem) => {
        return (
          <Tooltip
            key={dataInfoItem.terminalOperatorCode}
            placement="bottom"
            title={getTooltipText(dataInfoItem)}
          >
            <StyledGrid id={`DataInfoItem-${dataInfoItem.terminalOperatorCode}`} item>
              {dataInfoItem.status === DataSyncStatus.Successful && (
                <CheckCircleIcon htmlColor={Colours.SUCCESS_GREEN} />
              )}

              {dataInfoItem.status === DataSyncStatus.Failure && (
                <ErrorIcon htmlColor={Colours.OFFICIAL_POA_RED} />
              )}
              <StyledTypography>{dataInfoItem.terminalOperatorCode}</StyledTypography>
            </StyledGrid>
          </Tooltip>
        );
      })}
    </>
  );
}
