import { Typography, Link, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import SinkingIconSvg from './sinking.svg';

const StyledGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: ' center',
  textAlign: 'center',
  marginTop: 30
}));

const StyledLogo = styled('img')(() => ({
  height: '80px'
}));

export default function Forbidden() {
  const requestSubscriptionUrl = process.env.REACT_APP_REQUEST_SUBSCRIPTION_URL || '';
  const supportUrl = process.env.REACT_APP_POAOPENDATA_SUPPORT_URL || '';

  return (
    <StyledGrid container direction="column">
      <Grid item>
        <StyledLogo src={SinkingIconSvg} alt="Sinking icon" />
      </Grid>

      <Grid item>
        <Typography sx={{ fontWeight: 600 }}>Oops. You don't have access to this page.</Typography>

        <Grid item>
          Luckily, it's easy to get access to this data. Just{' '}
          <Link id="RequestSubscriptionLink" href={requestSubscriptionUrl} underline="hover">
            request a subscription
          </Link>{' '}
          here.
        </Grid>

        <Grid item>
          Subscribed but still getting this message? Try logging out and logging in again. If that
          does not help,{' '}
          <Link id="CreateSupportTicketLink" href={supportUrl} underline="hover">
            create a support ticket
          </Link>{' '}
          and we will help you out!
        </Grid>
      </Grid>
    </StyledGrid>
  );
}
