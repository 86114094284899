import { useEffect, useState } from 'react';
import { CONFIGURED_TERMINALS } from '../../../constants';
import { DataSyncStatus } from '../types/DataSyncStatus';
import DataInfoItem from '../types/DataInfoItem';
import DataInfoItemResponse from '../types/DataInfoItemResponse';
import { getDataInfo } from '../api/vesselEtaApiService';

const getInitialDataSyncList = (): Array<DataInfoItem> => {
  return CONFIGURED_TERMINALS.map((terminal) => ({
    terminalOperatorCode: terminal,
    terminalCodes: [],
    lastSuccessDateTime: null,
    status: DataSyncStatus.Failure
  }));
};

export function useDataInfoList() {
  const [dataInfoList, setDataInfoList] = useState<Array<DataInfoItem>>(() =>
    getInitialDataSyncList()
  );

  // If the api does not return Data-Info objects for all configured terminals, we complete the list with items with status Failure
  const mapAndCompleteApiResponse = (apiResponse: Array<DataInfoItemResponse>) => {
    const completedList: DataInfoItem[] = CONFIGURED_TERMINALS.map((terminal) => {
      const foundTerminalDataInfo = apiResponse.find((x) => x.terminalOperatorCode == terminal);

      if (foundTerminalDataInfo) {
        return {
          terminalOperatorCode: foundTerminalDataInfo.terminalOperatorCode,
          terminalCodes: foundTerminalDataInfo.terminalCodes,
          lastSuccessDateTime: new Date(foundTerminalDataInfo.lastSuccessDateTime),
          status: foundTerminalDataInfo.status as DataSyncStatus
        };
      } else {
        return {
          terminalOperatorCode: terminal,
          terminalCodes: [],
          lastSuccessDateTime: null,
          status: DataSyncStatus.Failure // no sync item found for terminal -> show Failure status
        };
      }
    });

    return completedList;
  };

  // load Data Info list when first mounting component
  useEffect(() => {
    const loadDataInfo = async () => {
      const apiResponse = await getDataInfo();
      const completedDataInfoList = mapAndCompleteApiResponse(apiResponse);
      setDataInfoList(completedDataInfoList);
    };

    loadDataInfo();
  }, []);

  return dataInfoList;
}
