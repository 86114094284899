import { Dispatch, SetStateAction, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  isAuthenticatedAsync,
  isAuthorizedAsync,
  signinRedirect
} from '../../services/authService';
import { RouteDefinition } from '../../routes';
import { routes as authRoutes } from './routes';

const authRoutePaths: string[] = authRoutes.map((route: RouteDefinition) => route.path);

interface Props {
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  setIsAuthorized: Dispatch<SetStateAction<boolean>>;
}

export default function Login(props: Props) {
  const location = useLocation();

  useEffect(() => {
    function shouldTriggerSignin(isAuthenticated: boolean): boolean {
      if (!isAuthenticated) {
        // Only trigger signin when it's not an authentication (callback) route
        if (!authRoutePaths.includes(location.pathname)) {
          return true;
        }
      }
      return false;
    }

    (async () => {
      const isAuthenticated: boolean = await isAuthenticatedAsync();
      props.setIsAuthenticated(isAuthenticated);

      if (shouldTriggerSignin(isAuthenticated)) {
        signinRedirect();
        return;
      }

      const isAuthorized: boolean = await isAuthorizedAsync();
      props.setIsAuthorized(isAuthorized);
    })();
  }, [location, props]);

  return (
    <Routes>
      {authRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
}
