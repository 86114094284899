import PagingParameters from '../../../types/pagingParameters';

export default interface SearchVesselInfoParameters extends PagingParameters {
  terminalOperatorCode: string | null;
  terminalCode: string | null;
  vesselName: string | null;
  imo: string | null;
  etaFrom: Date | null;
  orderBy: SearchVesselInfoOrderBy;
  order: SortOrder;
}

export enum SearchVesselInfoOrderBy {
  terminalOperatorCode = 'terminalOperatorCode',
  terminalCode = 'terminalCode',
  vesselName = 'vesselName',
  imo = 'imo',
  voyageOut = 'voyageOut',
  eta = 'eta',
  ata = 'ata',
  etd = 'etd',
  cargoOpening = 'cargoOpening'
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}
