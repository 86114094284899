import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ChangeEvent } from 'react';
import { format } from 'date-fns';
import VesselInfo from '../types/VesselInfo';
import SearchVesselInfoParameters from '../types/searchVesselInfoParameters';

const PREFIX = 'VesselsOverviewList';

const classes = {
  tableHeaderText: `${PREFIX}-tableHeaderText`
};

const StyledPaper = styled(Paper)(() => ({
  [`& .${classes.tableHeaderText}`]: {
    color: 'grey'
  }
}));

interface Props {
  vesselInfoList: Array<VesselInfo>;
  searchParams: SearchVesselInfoParameters;
  onChangePagingParameters(page: number, pageSize: number): void;
  onChangeOrdering(orderBy: string, order: 'asc' | 'desc'): void;
}

const tableHeadings = [
  { key: 'terminalCode', displayName: 'Terminal' },
  { key: 'terminalOperatorCode', displayName: 'Terminal Operator' },
  { key: 'vesselName', displayName: 'Vessel Name' },
  { key: 'imo', displayName: 'IMO' },
  { key: 'voyageIn', displayName: 'Voyage In' },
  { key: 'voyageOut', displayName: 'Voyage Out' },
  { key: 'eta', displayName: 'ETA' },
  { key: 'ata', displayName: 'ATA' },
  { key: 'etd', displayName: 'ETD' },
  { key: 'cargoOpening', displayName: 'Cargo Opening' },
  { key: 'cargoOpeningReefers', displayName: 'Cargo Opening Reefers' }
];

const dateFormat = 'dd MMM yyyy, HH:mm';

export default function VesselsOverviewList(props: Props) {
  const { vesselInfoList } = props;

  const handleChangePage = (page: number) => {
    props.onChangePagingParameters(page, props.searchParams.pageSize);
  };

  const handlePageSizeChange = (pageSize: number) => {
    props.onChangePagingParameters(props.searchParams.page, pageSize);
  };

  const handleOrderChange = (field: string) => {
    const fieldIsCurrentlySortedInAsc =
      props.searchParams.orderBy === field && props.searchParams.order === 'asc';
    const newOrderDirection = fieldIsCurrentlySortedInAsc ? 'desc' : 'asc';
    props.onChangeOrdering(field, newOrderDirection);
  };

  return (
    <StyledPaper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadings.map((heading) => (
                <TableCell
                  className={classes.tableHeaderText}
                  key={heading.key}
                  sortDirection={
                    props.searchParams.orderBy === heading.key ? props.searchParams.order : false
                  }
                >
                  <TableSortLabel
                    active={props.searchParams.orderBy === heading.key}
                    direction={
                      props.searchParams.orderBy === heading.key ? props.searchParams.order : 'asc'
                    }
                    onClick={() => handleOrderChange(heading.key)}
                  >
                    {heading.displayName}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {vesselInfoList.map((vesselinfo, i) => (
              <TableRow key={i} hover>
                <TableCell>{vesselinfo.terminalCode}</TableCell>
                <TableCell>{vesselinfo.terminalOperatorCode}</TableCell>
                <TableCell>{vesselinfo.vesselName}</TableCell>
                <TableCell>{vesselinfo.imo}</TableCell>
                <TableCell>{vesselinfo.voyageIn}</TableCell>
                <TableCell>{vesselinfo.voyageOut}</TableCell>
                <TableCell>{vesselinfo.eta ? format(vesselinfo.eta, dateFormat) : null}</TableCell>
                <TableCell>{vesselinfo.ata ? format(vesselinfo.ata, dateFormat) : null}</TableCell>
                <TableCell>{vesselinfo.etd ? format(vesselinfo.etd, dateFormat) : null}</TableCell>
                <TableCell>
                  {vesselinfo.cargoOpening ? format(vesselinfo.cargoOpening, dateFormat) : null}
                </TableCell>
                <TableCell>
                  {vesselinfo.cargoOpeningReefers
                    ? format(vesselinfo.cargoOpeningReefers, dateFormat)
                    : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 250]}
          component="div"
          count={props.searchParams.totalElements}
          rowsPerPage={props.searchParams.pageSize}
          page={props.searchParams.page - 1}
          onPageChange={(e, page) => handleChangePage(page + 1)}
          onRowsPerPageChange={(e: ChangeEvent<{ value: unknown }>) =>
            handlePageSizeChange(Number(e.target.value))
          }
        />
      </TableContainer>
    </StyledPaper>
  );
}
