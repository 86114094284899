import { createTheme } from '@mui/material';

// necessary for overriding mui/lab css! https://mui.com/components/about-the-lab/#typescript
import type {} from '@mui/lab/themeAugmentation';

export const Colours = {
  SUCCESS_GREEN: '#0aab0b',
  OFFICIAL_POA_RED: '#E4032C'
};

export const theme = createTheme({
  palette: {
    primary: {
      light: '#FA143E',
      main: Colours.OFFICIAL_POA_RED,
      dark: '#8f0019',
      contrastText: '#ffffff'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        gutterBottom: true
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: Colours.OFFICIAL_POA_RED
        },
        filledError: {
          backgroundColor: Colours.OFFICIAL_POA_RED
        }
      }
    }
  }
});
