import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Toolbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useState } from 'react';
import SearchVesselInfoParameters from '../types/searchVesselInfoParameters';
import LinearLoadingIndicator from '../../../components/elements/LinearLoadingIndicator';
import { DatePicker } from '@mui/lab';
import DataInfoItem from '../types/DataInfoItem';
import React from 'react';

const PREFIX = 'VesselsOverviewFilter';

const classes = {
  searchPaper: `${PREFIX}-searchPaper`,
  tableToolbar: `${PREFIX}-tableToolbar`,
  button: `${PREFIX}-button`,
  dropDown: `${PREFIX}-dropDown`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.searchPaper}`]: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },

  [`& .${classes.tableToolbar}`]: {
    width: '100%',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(2)
  },

  [`& .${classes.dropDown}`]: {
    width: 200
  }
}));

interface Props {
  isLoading: boolean;
  searchParams: SearchVesselInfoParameters;
  dataInfoItems: Array<DataInfoItem>;
  onSearch(): void;
  clearSearch(): void;
  onChangeSearchParam(name: string, value: string | null): void;
  onReplaceSearchParam(params: SearchVesselInfoParameters): void;
  getInitialFilters(): object;
  onChangeDateSearchParam(inputName: string, date: Date | null): void;
}

export default function VesselsOverviewFilter(props: Props) {
  const onChangeTextFieldSearchParam = (event: ChangeEvent<HTMLTextAreaElement>) => {
    props.onChangeSearchParam(event.target.name, event.target.value);
  };

  const getAllTerminalCodes = (): Array<string> => {
    return props.dataInfoItems.flatMap((info: DataInfoItem) => info.terminalCodes);
  };

  const getAllOperators = (): Array<string> => {
    return props.dataInfoItems.map((info: DataInfoItem) => info.terminalOperatorCode);
  };

  const [terminalCodes, setTerminalCodes] = React.useState<Array<string>>([]);
  const allOperators = getAllOperators();

  useEffect(() => {
    const allTerminalCodes = getAllTerminalCodes();
    if (allTerminalCodes.length > 0) setTerminalCodes(allTerminalCodes);
  }, [props.dataInfoItems]);

  useEffect(() => {
    if (
      props.searchParams.terminalCode == null &&
      props.searchParams.terminalOperatorCode == null
    ) {
      const allTerminalCodes = getAllTerminalCodes();
      setTerminalCodes(allTerminalCodes);
    }
  }, [props.searchParams]);

  const setAndReplaceSearchParams = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    const searchParams = props.searchParams;
    let selectedItem = null;

    switch (event.target.name) {
      case 'terminalCode':
        selectedItem = props.dataInfoItems.find((item) =>
          item.terminalCodes.some((code) => code == value)
        );
        searchParams.terminalCode = value == '' ? null : value;
        searchParams.terminalOperatorCode =
          selectedItem == undefined ? null : selectedItem.terminalOperatorCode;
        break;
      case 'terminalOperatorCode':
        selectedItem = props.dataInfoItems.find((item) => item.terminalOperatorCode == value);
        searchParams.terminalCode =
          selectedItem == undefined ? null : selectedItem.terminalCodes[0];
        searchParams.terminalOperatorCode =
          selectedItem == undefined ? null : selectedItem.terminalOperatorCode;
        setTerminalCodes(selectedItem == undefined ? [] : selectedItem.terminalCodes);
        break;
      default:
        break;
    }

    props.onReplaceSearchParam(searchParams);
  };

  const onChangeTerminalOperatorCode = (event: SelectChangeEvent) => {
    setAndReplaceSearchParams(event);
  };

  const onChangeTerminalCode = (event: SelectChangeEvent) => {
    setAndReplaceSearchParams(event);
  };

  const vesselNameFilterIsValid = (): boolean => {
    if (props.searchParams.vesselName) {
      return props.searchParams.vesselName.length >= 3;
    }
    return true;
  };

  return (
    <StyledPaper id="FilterPaper" className={classes.searchPaper}>
      <form
        onSubmit={(event) => {
          event.preventDefault(); // prevent page reload
          props.onSearch();
        }}
      >
        <Toolbar id="FilterContainer" className={classes.tableToolbar}>
          <Grid container spacing={2}>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel shrink>Terminal</InputLabel>
                <Select
                  name="terminalCode"
                  value={props.searchParams.terminalCode ?? ''}
                  onChange={(e) => onChangeTerminalCode(e)}
                  className={classes.dropDown}
                >
                  <MenuItem value={''}>-</MenuItem>

                  {terminalCodes.map((code) => (
                    <MenuItem key={code} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl variant="standard">
                <InputLabel shrink>Terminal Operator</InputLabel>
                <Select
                  name="terminalOperatorCode"
                  value={props.searchParams.terminalOperatorCode ?? ''}
                  onChange={(e) => {
                    onChangeTerminalOperatorCode(e);
                  }}
                  className={classes.dropDown}
                >
                  <MenuItem value={''}>-</MenuItem>

                  {allOperators.map((operator) => (
                    <MenuItem key={operator} value={operator}>
                      {operator}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Vessel Name"
                name="vesselName"
                value={props.searchParams.vesselName ?? ''}
                onChange={onChangeTextFieldSearchParam}
                error={!vesselNameFilterIsValid()}
                helperText={vesselNameFilterIsValid() ? ' ' : 'Enter at least 3 characters'}
                variant="standard"
              />
            </Grid>

            <Grid item>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="IMO"
                name="imo"
                value={props.searchParams.imo ?? ''}
                onChange={onChangeTextFieldSearchParam}
                variant="standard"
              />
            </Grid>
            <Grid item>
              <DatePicker
                value={props.searchParams.etaFrom}
                onChange={(date) => props.onChangeDateSearchParam('etaFrom', date)}
                inputFormat="yyyy-MM-dd HH:mm"
                mask="____-__-__ __:__"
                label="ETA From"
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    InputLabelProps={{
                      shrink: true
                    }}
                    {...params}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Button
            id="VesselSearchButton"
            variant="contained"
            color="primary"
            disabled={props.isLoading || !vesselNameFilterIsValid()}
            onClick={props.onSearch}
            type="submit"
          >
            <SearchIcon />
            {props.isLoading ? 'Loading...' : 'Search'}
          </Button>
          <Button
            id="ClearSearchButton"
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={props.clearSearch}
          >
            Clear
          </Button>
        </Toolbar>
        <LinearLoadingIndicator isLoading={props.isLoading} />
      </form>
    </StyledPaper>
  );
}
