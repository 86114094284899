import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getAccessTokenAsync } from '../services/authService';
import { useNotificationStore } from '../stores/notifications';

async function authRequestInterceptor(config: AxiosRequestConfig) {
  if (!config || !config.headers) {
    return;
  }

  const bearerToken = await getAccessTokenAsync();
  if (bearerToken) {
    config.headers.authorization = `Bearer ${bearerToken}`;
  }

  config.headers.Accept = 'application/json';
  config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY ?? '';
  return config;
}

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_VESSEL_ETA_API_URL
});

axios.interceptors.request.use(authRequestInterceptor);

function isBadRequestResponse(error: AxiosError) {
  return error?.response?.status === 400;
}

function tryGetValidationMessages(error: AxiosError) {
  const validationErrorsObject = error?.response?.data?.errors;
  if (validationErrorsObject) {
    const validationErrors = Object.values(validationErrorsObject).flatMap((x) => x);
    return validationErrors.join(', ');
  }
  return null;
}

axios.interceptors.response.use(
  (response) => response, // in case of response code 2xx
  (error) => {
    // callback in case of non 2xx response code

    let validationErrorMessages;
    if (isBadRequestResponse(error)) {
      validationErrorMessages = tryGetValidationMessages(error);
    }

    useNotificationStore
      .getState()
      .addErrorNotification('Error', validationErrorMessages ?? error.message);

    return Promise.reject(error);
  }
);
