export const CENTRALIZED_VESSEL_ETA_API_ASSET_ID = '00000000-0000-0001-0000-000000000057';
export const CONFIGURED_TERMINALS = ['DPW', 'PSA', 'MPET', 'CSPZ'];
export const DISCLAIMER_TEXT =
  'The information/data as shown on this site gives only general guidance (consolidation) and is strictly informative. Source data originates from the specific terminal operators’ website. ' +
  'POAB/NxtPort do not represent or warrant that the information accessible via the website is accurate, complete or up to date. It is your responsibility to check that the information is still valid and correct. ' +
  'For further information you should contact the specific terminal operator. POAB and/or NxtPort will not be liable (to the fullest extent permitted at law) for any loss, damage or inconvenience arising out of ' +
  'and/or as a consequence of any use of or the inability to use any information/data on this site and of the site. POAB/NxtPort take no responsibility for the contents of the shown websites and links should not be taken as endorsement of any kind. ' +
  'POAB/NxtPort have no control over the availability of the linked pages. POAB/NxtPort does not warrant that the shown websites are free of erros, viruses or bugs, it remains your responsibility to make adequate provisions for protection againts such threats.';
export const DISCLAIMER_TEXT_MPET =
  'MPET K1742 represents both sides of the terminal, quays 1718 & 1742. For further information regarding berth location, please contact MPET.';
export const DISCLAIMER_TEXT_MPET_OPENING_TIME =
  'Cargo opening time for the MPET terminal is only applicable for Dry Van Containers. ' +
  'Please consult the MPET website (https://www.mpet.be/delivery-times.htm) for the controlled delivery time for operational reefers.';
