import { useEffect, useState } from 'react';
import { getNameAsync } from '../../services/authService';

export default function useUserName() {
  const [userName, setName] = useState<string | null>(null);

  useEffect(() => {
    const loadAndSetName = async () => {
      const name = await getNameAsync();

      if (name) {
        setName(name);
      }
    };

    loadAndSetName();
  });
  return userName;
}
