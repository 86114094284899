import { RouteDefinition } from '../../routes';
import SigninCallback from './SigninCallback';
import SignoutCallback from './SignoutCallback';
import SigninSilentCallback from './SigninSilentCallback';

export const routes: RouteDefinition[] = [
  {
    path: '/signin-oidc',
    component: <SigninCallback />
  },
  {
    path: '/signout-callback-oidc',
    component: <SignoutCallback />
  },
  {
    path: '/signin-silent-oidc',
    component: <SigninSilentCallback />
  }
];
