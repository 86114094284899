import React, { useState } from 'react';
import Layout from './components/layout/Layout';
import Login from './components/auth/Login';
import AuthorizedContext from './components/auth/AuthorizedContext';
import Notifications from './components/notifications/Notifications';

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  function getLogin() {
    return <Login setIsAuthenticated={setIsAuthenticated} setIsAuthorized={setIsAuthorized} />;
  }

  function getAppContent() {
    return (
      <AuthorizedContext.Provider value={isAuthorized}>
        <Layout />
        <Notifications />
      </AuthorizedContext.Provider>
    );
  }

  return isAuthenticated ? getAppContent() : getLogin();
}
