import VesselInfoResponse from '../types/VesselInfoResponse';
import VesselInfo from '../types/VesselInfo';
import GetVesselsInfoResponse from '../types/GetVesselsInfoResponse';
import GetVesselsInfoResult from '../types/GetVesselInfoResult';

const mapVesselInfoResponse = (vesselInfoResponse: VesselInfoResponse): VesselInfo => {
  return {
    terminalOperatorCode: vesselInfoResponse.terminalOperatorCode,
    terminalCode: vesselInfoResponse.terminalCode,
    vesselName: vesselInfoResponse.vesselName,
    imo: vesselInfoResponse.imo,
    voyageIn: vesselInfoResponse.voyageIn,
    voyageOut: vesselInfoResponse.voyageOut,
    eta: vesselInfoResponse.eta ? new Date(vesselInfoResponse.eta) : null,
    ata: vesselInfoResponse.ata ? new Date(vesselInfoResponse.ata) : null,
    etd: vesselInfoResponse.etd ? new Date(vesselInfoResponse.etd) : null,
    cargoOpening: vesselInfoResponse.cargoOpening
      ? new Date(vesselInfoResponse.cargoOpening)
      : null,
    cargoOpeningReefers: vesselInfoResponse.cargoOpeningReefers
      ? new Date(vesselInfoResponse.cargoOpeningReefers)
      : null
  };
};

export const mapGetVesselInfoResponse = (
  response: GetVesselsInfoResponse
): GetVesselsInfoResult => {
  return {
    items: response.items.map((x) => mapVesselInfoResponse(x)),
    paging: {
      pageSize: response._page.size,
      page: response._page.number,
      totalElements: response._page.totalElements
    }
  };
};
